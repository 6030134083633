import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";

function EventTicketSkeleton() {
  return (
    <>
      <div className="p-2 w-[140px] h-auto  max-w-xs rounded-lg mt-2 flex flex-col items-start justify-center space-y-0.5 border border-gray-300">
        <div className="bg-gray-300 h-5 w-full rounded-md animate-pulse"></div>
        <div className="bg-gray-300 h-5 w-full rounded-md animate-pulse"></div>
        <div className="flex flex-row space-x-2 ml-1">
          <IoIosInformationCircleOutline className="text-deepBlue mt-1 hover:text-customGrey w-[16px] h-[16px]" />
          <div className="bg-gray-300 h-4 w-24 mt-0.5 rounded-md animate-pulse"></div>
        </div>
        <hr className="bg-primaryBlack h-0.5 w-[122px]" />
        <div className="flex flex-row space-x-8  h-[]">
          <button className="rounded-md bg-primaryBlack h-[16px] w-[16px] mt-1.5 text-white">
            <FontAwesomeIcon icon={faMinus} className="w-[6px] mb-2 " inverse />
          </button>
          <div className="bg-gray-300 h-5 mt-1 w-6 rounded-lg"></div>
          <button
            className={`rounded-md bg-primaryBlack h-[16px] w-[16px] mt-1.5 `}
          >
            <FontAwesomeIcon icon={faPlus} className="w-[6px] mb-2" inverse />
          </button>
        </div>
      </div>
    </>
  );
}

export default EventTicketSkeleton;
