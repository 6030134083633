import { faMagnifyingGlass, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

function EventFilter({ search, handleSearch, handleClear }) {
  const [isClicked, setIsClicked] = useState(false);

  const inputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = () => setIsClicked(true);
  const handleBlur = () => {
    if (!search) setIsClicked(false);
  };
  const handleClearClick = (e) => {
    e.stopPropagation();
    handleClear();
  };
  return (
    <div
      ref={inputRef}
      className={`container relative w-full mx-auto max-w-6xl h-[70px] shadow-lg flex-shrink-0 rounded-3xl transform ${
        isClicked ? "bg-white" : "bg-darkNavyBlue"
      }`}
      onClick={handleClick}
    >
      <div className="flex flex-row items-center h-full px-8 sm:px-8">
        <div className="flex items-center gap-2  w-full">
          {!isClicked && (
            <>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                size="2xl"
                color="white"
              />
              <label
                htmlFor="searchEvent"
                className="text-white font-roboto text-sm font-normal leading-6"
              >
                Search Event
              </label>
            </>
          )}
          {isClicked && (
            <input
              value={search}
              onChange={handleSearch}
              onBlur={handleBlur} // Add blur event to handle state reset
              className="w-full bg-white focus:outline-none border-none text-darkNavyBlue placeholder-gray-500 font-roboto text-lg font-semibold leading-7 p-1 rounded-3xl"
              placeholder="Purple Party"
              autoFocus
            />
          )}
          {search && (
            <FontAwesomeIcon
              icon={faX}
              size="lg"
              onClick={handleClearClick}
              className="absolute right-8 text-darkNavyBlue focus:outline-none"
            />
            // <button
            //   onClick={handleClear}
            //   className="absolute right-8 text-darkNavyBlue focus:outline-none"
            // >
            //   &times;
            // </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventFilter;
