import toast from "react-hot-toast";
import Swal from "sweetalert2";
import Endpoints from "../util/Endpoints";
const { SERVER_HOST, API_URL, CONFIRM_PAYMENT } = Endpoints;

export const postConfirmPayment = (transactionId) => {
  toast.loading("Confirming Payment", {
    position: "top-right",
    duration: 3000,
    className: "mt-24 ",
  });

  Swal.fire({
    title: `<h4 class="text-center font-roboto font-medium text-base md:text-2xl lg:text-[28px] xl:text-4xl text-darkNavyBlue">Confirming...</h4> `,
    html: `
  <div>         
      <p class='font-roboto text-base md:text-base font-normal leading-6 md:leading-7'>Confirming the Payment...</p> 
  </div>`,
    showCancelButton: false,
    showConfirmButton: false,
  });

  fetch(`${SERVER_HOST}${API_URL}${CONFIRM_PAYMENT}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ transactionid: transactionId }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error("Network data was not ok.");
      }
      return res.json();
    })
    .then((data) => {
      let result_code = data.result_code;
      let message = data.message;

      if (result_code === 0) {
        toast.success(message, {
          position: "top-right",
          duration: 3000,
          className: "mt-24 ",
        });

        Swal.fire({
          icon: "success",
          iconColor: "#4CAF50",
          title: `<h4 class="text-center font-roboto font-medium text-base md:text-2xl lg:text-[28px] xl:text-4xl text-darkNavyBlue">
            <i class="fas fa-clock"></i> Payment Successful
          </h4>`,
          html: `
                <div class='gap-4'>                    
                    <p class='font-roboto text-base md:text-base font-normal leading-6 md:leading-7'>Ticket(s) purchased successfully. You will receive an SMS or email notification shortly with your e-Ticket(s).</p> 
                </div>`,
          showCancelButton: false,
          confirmButtonColor: "#4CAF50",
          confirmButtonText: "View More Events",
        }).then((result) => {
          if (result.isConfirmed) {
            //Redirect to events page
            window.location.href = "/events";
          }
        });
      } else if (result_code === 1) {
        toast.loading(message, {
          position: "top-right",
          duration: 3000,
          className: "mt-24 ",
        });
        //write code to check if payment is pending

        Swal.fire({
          icon: "info",
          iconColor: "#F44336",
          title: `<h4 class="text-center font-roboto font-medium text-base md:text-2xl lg:text-[28px] xl:text-4xl text-darkNavyBlue">
              <i class="fas fa-clock"></i> Payment Pending
            </h4>`,
          html: `
              <div>         
                <p class='font-roboto text-base md:text-base font-normal leading-6 md:leading-7'>Payment is still pending. Try again shortly.</p> 
              </div>`,
          showCancelButton: true,
          confirmButtonColor: "#4CAF50",
          confirmButtonText: "Confirm payment again",
          cancelButtonText: "Done",
          cancelButtonColor: "#EF4444",
        }).then((result) => {
          if (result.isConfirmed) {
            //add code to check the payment again
            postConfirmPayment(transactionId);
          }
        });
      }
    });
};
