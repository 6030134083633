import React, { useContext } from "react";
import "../Dashboard/dashboard.css";
import EventFilter from "./EventFilter";
import EventCard from "./EventCard";
import { useEvents } from "../../hooks/useEvents";
import CardSkeleton from "./CardSkeleton";
import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet";
import { SearchContext } from "../../components/context/useSearch";
import SearchedEvents from "./SearchedEvents";

function AllEvents() {
  const { allEvents,   isLoading: eventsLoading, } = useEvents();
  const {
    searchedEvents,
    search,
    debouncedQuery,
    handleSearch,
    handleClear,
    isLoading: searchLoading,
  } = useContext(SearchContext);

  const eventList = allEvents.map((event, index) => (
    <EventCard key={index} event={event} />
  ));

  return (
    <>
      <Helmet>
        <title>Explore Page</title>
      </Helmet>
      <div className="relative w-screen mt-14 sm:mt-20 md:mt-20 lg:max-w-7xl mx-auto flex flex-col gap-6 pb-[40px]">
        <div className=" hidden lg:flex pt-12 ">
          <EventFilter
            search={search}
            handleSearch={handleSearch}  
            handleClear={handleClear}          
          />
        </div>
        <div className="relative w-screen lg:max-w-7xl mx-auto flex flex-col gap-6 pb-[40px] pt-4">
          {eventsLoading ? (
            <div className="flex flex-col mx-auto justify-center p-3 sm:grid sm:grid-cols-2 mt-4 sm:ml-3 sm:mr-3 md:grid-cols-3 xl:grid-cols-4 gap-2">
              {Array.from({ length: 25 }, (_, index) => (
                <div
                  className={`skeleton-wrapper`}
                  key={`card-${index}-${uuidv4()}`}
                >
                  <CardSkeleton />
                </div>
              ))}
            </div>
          ) : (
            <div
              className={`event-list ${
                eventsLoading ? "event-list-hidden" : "event-list-visible"
              } flex flex-col justify-center mx-auto sm:grid sm:grid-cols-2 mt-4 sm:ml-3 sm:mr-3 md:grid-cols-3 xl:grid-cols-4 gap-2`}
            >
               {!!debouncedQuery ? (
                  <SearchedEvents
                    searchedEvents={searchedEvents}
                    isLoading={searchLoading}
                    search={debouncedQuery}
                  />
                ) : (
                  eventList
                )}
           
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AllEvents;
