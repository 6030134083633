import React from "react";
import { truncate } from "../util/Util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useEventDetails } from "../hooks/useEventDetails";
import Endpoints from "../util/Endpoints";

function EventDetailsHandler({ event }) {
  const { mapsUrl } = useEventDetails(event);
  return (
    <div className="lg:p-4 flex flex-col w-full space-y-2 lg:space-y-3 ">
      <div>
        <div className="p-1">
          <p className="text-primaryBlue font-roboto font-medium text-xl text-[23px]">
            {truncate(event.eventname, 30)}
          </p>
        </div>
      </div>
      <div className="">
        <div className="p-1 ">
          <p className="flex flex-row space-x-4">
            <span>
              <FontAwesomeIcon
                icon={faCalendarDays}
                size="lg"
                className="text-customGrey"
              />
            </span>
            <span className="font-roboto text-md font-medium leading-relaxed text-customGrey">
              {event.eventstartdate}
            </span>
          </p>
        </div>
        <div className="p-1 ">
          <p className="flex flex-row space-x-4">
            <span>
              <FontAwesomeIcon
                icon={faClock}
                size="lg"
                className="text-customGrey"
              />
            </span>
            <span className="font-roboto text-md font-medium leading-relaxed text-customGrey">
              {event.eventtime}
            </span>
          </p>
          <div className="p-1 ">
            <p className="flex flex-row space-x-4">
              <span>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  size="lg"
                  className="text-customGrey"
                />
              </span>
              <span className="font-roboto text-md font-medium leading-relaxed text-denimBlue underline hover:underline-offset-4">
                <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
                  {event.eventlocationname}
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="h-[340px] w-[340px] sm:h-[320px] sm:w-[320px] md:w-[360px] md:h-[500px] lg:w-[420px] lg:h-[550px]">
        <img
          className="w-full h-full rounded-3xl p-1"
          src={`${Endpoints.SERVER_HOST}/eventpics/${event.eventid}_banner_pic`}
          alt="event poster"
        />
      </div>
    </div>
  );
}

export default EventDetailsHandler;
