import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import EventTicketSkeleton from "./EventTicketSkeleton";
import { ticketColours } from "../../util/Util";

const EventDetailsSkeleton = () => (
    
  <div className="mx-auto w-screen mt-4 sm:mt-6 md:mt-10 lg:max-w-7xl">
    <div className="h-auto flex flex-col lg:ml-16 sm:flex-row md:space-x-6 p-2">
      <div className="lg:p-5 flex flex-col w-[330px] md:w-[500px] space-y-2 lg:space-y-3">
        <div>
          <div className="p-1">
            <div className="bg-gray-300 animate-pulse w-3/4 h-6 rounded-md"></div>
          </div>
        </div>
        <div>
          <div className="p-1">
            <div className="flex flex-row gap-4">
              <span>
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  size="lg"
                  className="text-customGrey"
                />
              </span>
              <span className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></span>
            </div>
          </div>
          <div className="p-1">
            <div className="flex flex-row gap-3.5">
              <span>
                <FontAwesomeIcon
                  icon={faClock}
                  size="lg"
                  className="text-customGrey"
                />
              </span>
              <span className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></span>
            </div>
            <div className="p-1 mt-1">
              <div className="flex flex-row gap-4">
                <span>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    size="lg"
                    className="text-customGrey"
                  />
                </span>
                <span className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-gray-300 animate-pulse h-[250px] w-[300px] sm:h-[400px] rounded-lg md:min-h-full md:max-w-md md:max-h-[410px] md:w-[360px] md:h-[420px] p-1"></div>
        </div>
      </div>
      <div className="flex flex-col space-y-2 w-[350px] sm:w-[320px] md:w-[350px] lg:w-[670px] h-[120px] mt-6 p-4 lg:p-1">
        <div className="space-y-2 w-[460px] h-[120px] mt-6 hidden lg:flex">
          <div className="hidden lg:flex md:flex-col md:space-y-2">
          <div className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></div>
          <div className="bg-gray-300 animate-pulse md:h-[430px] md:w-[430px] w-[380px] h-[440px] lg:h-[320px] lg:w-[620px] p-4 lg:p-1 rounded-md"></div>
          </div>
          
        </div>
        <div className=" md:h-[430px] md:w-[430px] w-[310px] h-[440px] lg:h-[320px] lg:w-[460px] p-1 lg:p-1">
          <div className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></div>
          <div className="grid grid-cols-2 gap-3 lg:grid lg:grid-cols-3 lg:gap-0">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="w-1/3 mb-4">
               <EventTicketSkeleton color={ticketColours[index % ticketColours.length]} />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col space-y-4 p-5 lg:p-1">
          {/* <div className="bg-gray-300 animate-pulse w-full h-12 rounded-lg"></div> */}
          <button
            disabled
            className="bg-secondaryOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base w-[320px] sm:w-[320px] md:w-[350px] lg:w-[460px]"
          >
            Continue To Check Out
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default EventDetailsSkeleton;
