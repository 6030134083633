import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../images/gig_logo 1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { SearchContext } from "../context/useSearch";

function Navbar() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const location = useLocation();

  const { search, handleSearch, handleClear } = useContext(SearchContext);

  const handleBlur = () => {
    if (!search) setIsClicked(false);
  };

  const handleClearClick = (e) => {
    e.stopPropagation();
    handleClear();
    setIsSearchOpen(false);
  };

  const navLinks = [{ to: "/events", text: "All Events" }];

  return (
    <>
      <nav className="bg-white w-screen fixed mx-auto  z-20 top-0 border border-gray-200 shadow-lg shadow-shadeOfGrey">
        <div className="max-w-screen-xl flex justify-between items-center mx-auto p-2 sm:px-6 ">
          <div className="flex items-center space-x-3 rtl:space-x-reverse">
            <NavLink to="/">
              <img className="h-10 sm:h-12 w-auto" src={logo} alt="Gig Logo" />
            </NavLink>
          </div>
          {/* <div
            className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse ml:auto md:ml-0 lg:hidden"
            onClick={() => setOpenNavbar((prev) => !prev)}
          >
            <div className="lg:hidden flex flex-row gap-4">
            <FontAwesomeIcon icon={faMagnifyingGlass} size="xl"  onClick={() => setIsSearchOpen(true)}/>
              {!openNavbar ? (
                <FontAwesomeIcon icon={faBars} size="xl" />
              ) : (
                <FontAwesomeIcon icon={faX} size="xl" />
              )}
            </div>
          </div> */}
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse ml:auto md:ml-0 lg:hidden">
            <div className="lg:hidden ">             
              <div className="h-6 flex flex-row gap-4 items-center p-1">
                {(location.pathname === "/" ||
                  location.pathname === "/events") &&
                  (isSearchOpen ? (
                    <div className="relative flex-1">
                      <input
                        value={search}
                        onChange={handleSearch}
                        onBlur={handleBlur}
                        className="w-full h-5 bg-transparent focus:outline-none border border-gray-300 text-darkNavyBlue placeholder-gray-500 font-roboto text-sm font-semibold leading-7 p-1 pl-10 rounded-3xl"
                        placeholder="Purple Party"
                        autoFocus
                      />
                      <FontAwesomeIcon
                        icon={faX}
                        size="xs"
                        onClick={handleClearClick}
                        className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer"
                      />
                    </div>
                  ) : (
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      size="sm"
                      onClick={() => setIsSearchOpen(true)}
                    />
                  ))}
                {!openNavbar ? (
                  <FontAwesomeIcon
                    icon={faBars}
                    size="sm"
                    onClick={() => setOpenNavbar(true)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faX}
                    size="sm"
                    onClick={() => setOpenNavbar(false)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="items-center justify-between hidden h-full lg:flex md:w-auto md:order-1">
            <ul className="flex flex-col p-4 md:p-0 font-medium sm:space-x--4 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 border border-gray-100 rounded-lg bg-gray-50 md:border-0 md:bg-white mt-4">
              <li
                className="text-deepBlue rounded-md  text-base font-medium hover:text-customOrange"
                aria-current="page"
              >
                <NavLink to="/events">All Events</NavLink>
              </li>

              <li
                className="text-deepBlue rounded-md text-base font-medium hover:text-customOrange"
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                {/* When i hover here show dropdown below */}
                <div className="flex items-center cursor-pointer">
                  <p>Event Organizer Account</p>
                  <svg
                    className="ml-2 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                {isDropdownOpen && (
                  <div className="absolute z-50 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      <p
                        onClick={() => {
                          window.open(
                            "https://client.gig.co.ke/client-register",
                            "_blank"
                          );
                        }}
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                      >
                        Create Events
                      </p>
                      <p
                        onClick={() => {
                          window.open(
                            "https://client.gig.co.ke/login",
                            "_blank"
                          );
                        }}
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                      >
                        Event Organizer Login
                      </p>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {openNavbar && (
        <div className=" z-30 inset-0  mt-16 flex justify-end backdrop-blur-sm fixed">
          <ul className="w-full h-60 bg-white shadow-md">
            {navLinks.map((link, index) => (
              <li
                key={index}
                className="text-deepBlue border-b rounded-md px-8 py-2 text-base font-medium hover:underline"
              >
                <NavLink to={link.to} onClick={() => setOpenNavbar(false)}>
                  {link.text}
                </NavLink>
              </li>
            ))}
            <li
              onClick={() => {
                window.open(
                  "https://client.gig.co.ke/client-register",
                  "_blank"
                );
              }}
              className="text-deepBlue border-b rounded-md px-8 py-2 text-base font-medium hover:underline cursor-pointer"
            >
              Create Events
            </li>
            <li
              onClick={() => {
                window.open("https://client.gig.co.ke/login", "_blank");
              }}
              className="text-deepBlue border-b rounded-md px-8 py-2 text-base font-medium hover:underline cursor-pointer"
            >
              Event Organizer Login
            </li>
          </ul>
        </div>
      )}
    </>
  );
}

export default Navbar;
