import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faCircleInfo,
  faMinus,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { truncate } from "../../util/Util";
import { IoIosInformationCircleOutline } from "react-icons/io";

function EventTicket({ ticket, index, handleTicketChange }) {
  const [ticketCount, setTicketCount] = useState(0);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const incrementTicket = () => {
    setTicketCount(ticketCount + 1);
    handleTicketChange(
      ticket.tickettype,
      ticketCount + 1,
      ticket.ticket_amount,
      ticket.ticketeventid,
      ticket.tickettypeid,
      ticket.id,
      ticket.ticketmode,
      ticket.ticketmodenumber
    );
  };

  const decrementTicket = () => {
    if (ticketCount > 0) {
      setTicketCount(ticketCount - 1);
      handleTicketChange(
        ticket.tickettype,
        ticketCount - 1,
        ticket.ticket_amount,
        ticket.ticketeventid,
        ticket.tickettypeid,
        ticket.id,
        ticket.ticketmode,
        ticket.ticketmodenumber
      );
    }
  };

  const toggleMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  return (
    <>
      <div className="w-[260px] lg:w-[240px] h-[180px] xl:w-[260px] rounded-lg mt-2 flex flex-col  border-[1.7px] border-deepBlue">
        <div className="p-5 w-full h-full  items-start space-y-2">
          <h4
            className="font-roboto text-[16px] font-medium leading-6 text-deepBlue ml-1 cursor-pointer w-full"
            title={ticket.tickettype}
          >
            {truncate(ticket.tickettype, 18)}
          </h4>
          <h4 className="font-roboto text-[16px] font-medium leading-6 text-deepBlue ml-1 w-full">
            {ticket.currency.code}{" "}
            {ticket.ticketmode === "group"
              ? (
                  ticket.ticket_amount * ticket.ticketmodenumber
                ).toLocaleString()
              : ticket.ticket_amount.toLocaleString()}
          </h4>
          <div
            className="flex flex-row space-x-2 ml-1 w-full"
            onClick={toggleMoreInfo}
          >
            <IoIosInformationCircleOutline className="text-deepBlue mt-1 hover:text-customGrey w-[20px] h-[20px]" />
            <p className="underline text-spaceBlack hover:text-customBlue font-roboto text-[14px] mt-0.5 font-normal cursor-pointer">
              See more info
            </p>
          </div>
          <hr className="bg-primaryBlack w-[calc(100% + 2.5rem)] -ml-5 -mr-5 h-0.5 " />
          <div className="flex flex-row justify-between items-center w-full">
            {ticket.status === "sold_out" ? (
              <p className="font-roboto text-xl flex justify-center text-center font-medium text-rose-500 mx-auto mt-1.5">
                Sold Out!!
              </p>
            ) : (
              <>
                <button
                  onClick={() => decrementTicket()}
                  className="rounded-md border border-deepBlue h-[35px] w-[35px] mt-1.5 text-white flex justify-center items-center"
                >
                  <FontAwesomeIcon
                    icon={faMinus}
                    className="w-[30px] h-[16px] text-deepBlue"
                    inverse
                  />
                </button>
                <span className="mt-0.5 font-roboto text-xl md:text-2xl not-italic font-medium leading-normal">
                  {ticketCount}
                </span>
                <button
                  onClick={() => incrementTicket()}
                  className={`rounded-md border border-deepBlue h-[35px] w-[35px]  mt-1.5 flex justify-center items-center ${
                    ticketCount >= 1 ? "bg-deepBlue" : ""
                  } `}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={`w-[20px] h-[16px] ${
                      ticketCount >= 1 ? "text-white" : "text-deepBlue"
                    }`}
                    inverse
                  />
                </button>
              </>
            )}
          </div>
        </div>
        {showMoreInfo && (
          <div className="p-3 lg:w-[255px] lg:h-auto  max-w-xs rounded-lg flex flex-col items-start bg-peachyBeige z-20">
            <div className="flex flex-row justify-between w-full">
              <h4
                className="font-roboto text-[13px] font-medium leading-6 text-deepBlue ml-1 cursor-pointer w-full"
                title={ticket.tickettype}
              >
                {truncate(ticket.tickettype, 24)}
              </h4>
              <FontAwesomeIcon
                icon={faTimes}
                className="w-[25px] h-[14px] text-deepBlue cursor-pointer p-1"
                onClick={() => setShowMoreInfo(false)}
                inverse
              />
            </div>
            <p className="font-roboto text-[14px] font-normal leading-6 text-spaceBlack w-full">
              {ticket.description}
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default EventTicket;
